<template>
  <v-container>
    <v-layout column align-center justify-center mx-5>
      <v-flex xs12 sm6>
        <slices-block :slices="slices" />
      </v-flex>
    </v-layout>

    <v-layout column align-center justify-center>
      <v-btn class="takein_logo mt-5 px-5 py-4" dark rounded to="/signup">
        {{ $t("Common.signup") }}
      </v-btn>
    </v-layout>
    <v-layout column align-center justify-center mx-5>
      <v-row no-gutters class="mt-16">
        <v-col>
          <h3>Frequently Asked Questions for Restaurant Owners</h3>

          <b>Q: How does TakeIn offer a commission-free experience?</b>
          <p>
            A: TakeIn operate on a customer mission model, pick up or delivery
            commission varies between 5% to 15%
          </p>

          <b>Q: Are there setup fees for restaurants on TakeIn?</b>
          <p>A: Absolutely not! Joining TakeIn is free for all restaurants.</p>

          <b>Q: Can I cancel my restaurant's account anytime? </b>
          <p>
            A: Yes, our flexible model allows restaurants to cancel anytime
            without penalties.
          </p>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>
<script>
import SlicesBlock from "@/components/SlicesBlock.vue";

export default {
  components: { SlicesBlock },

  metaInfo() {
    return {
      title: this.title,

      bodyAttrs: {
        class: "app-1",
      },
      meta: [
        { name: "og:title", content: this.title },
        { name: "description", content: this.description, vmid: "takein" },
        { name: "og:description", content: this.description },
        { name: "og:type", content: "website" },
        { name: "og:url", content: "https://biz.takein.com/pricing" },
        {
          name: "og:image",
          content:
            "https://storage.googleapis.com/download.takein.com/takein.webp",
        },
      ],
      link: [{ rel: "canonical", href: "https://biz.takein.com/pricing" }],
      script: this.script,
    };
  },
  mounted() {
    //Query to get post content

    this.$prismic.client.getByUID("page", "takein-pricing").then((response) => {
      this.slices = response.data.page_content;
      // document contains the document content
    });
  },
  data() {
    return {
      slices: null,

      title: "TakeIn Pricing: Commission-Free Restaurant Delivery Platform",
      script: [
        { innerHTML: "var appId=1.1", body: true },
        { innerHTML: "var appId=1.2", vmid: "takein" },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "Product",
            name: "TakeIn Restaurant Platform",
            image:
              "https://storage.googleapis.com/download.takein.com/takein.webp",
            description:
              "A commission-free restaurant delivery platform designed to boost profits for local restaurants.",
            brand: {
              "@type": "Brand",
              name: "TakeIn",
            },
            offers: {
              "@type": "Offer",
              priceCurrency: "USD",
              price: "0.00",
              availability: "http://schema.org/InStock",
              url:
                "https://storage.googleapis.com/download.takein.com/takein.webp",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "5",
              bestRating: "5",
              ratingCount: "500",
            },
          },
        },
      ],
      description:
        "Discover TakeIn, the best commission-free delivery platform tailored for local restaurants. Boost profits, enjoy transparent pricing, and experience flexibility without long-term contracts. Join the revolution today!",
    };
  },
};
</script>
<style>
.full-width-image img {
  width: 40%;
  height: 40%;
}
</style>
